var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('b-row',[_c('b-col',{attrs:{"md":6}},[_c('b-form-group',{attrs:{"id":"name-group","label":_vm.$t('FORM_LABELS.NAME'),"label-for":"name-input"}},[_c('FormInput',{attrs:{"id":"name-input","type":"text","name":"name","i18n":{
            label: 'FORM_LABELS.NAME',
            placeholder: _vm.$t('FORM_LABELS.NAME')
          },"validateState":_vm.validateState('name')},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1)],1),_c('b-col',{attrs:{"md":6}},[_c('b-form-group',{attrs:{"id":"address-group","label":_vm.$t('FORM_LABELS.ADDRESS'),"label-for":"address-input"}},[_c('FormInput',{attrs:{"id":"address-input","type":"text","name":"address","i18n":{
            label: 'FORM_LABELS.ADDRESS',
            placeholder: _vm.$t('FORM_LABELS.ADDRESS')
          }},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}})],1)],1),_c('b-col',{attrs:{"md":6}},[_c('b-form-group',{attrs:{"id":"phone-group","label":_vm.$t('FORM_LABELS.PHONE'),"label-for":"phone-input"}},[_c('FormInput',{attrs:{"id":"phone-input","type":"number","name":"phone","i18n":{
            label: 'FORM_LABELS.PHONE',
            placeholder: _vm.$t('FORM_LABELS.PHONE')
          }},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})],1)],1),_c('b-col',{attrs:{"md":6}},[_c('b-form-group',{attrs:{"id":"bulstat-group","label":_vm.$t('FORM_LABELS.BULSTAT'),"label-for":"bulstat-input"}},[_c('FormInput',{attrs:{"id":"bulstat-input","type":"text","name":"bulstat","i18n":{
            label: 'FORM_LABELS.BULSTAT',
            placeholder: _vm.$t('FORM_LABELS.BULSTAT')
          }},model:{value:(_vm.form.bulstat),callback:function ($$v) {_vm.$set(_vm.form, "bulstat", $$v)},expression:"form.bulstat"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }